import React from 'react';
import { Link } from 'gatsby';
import SectionContainer from '../components/SectionContainer';

const NotFoundPage = () => (
  <div className="not-found-page center">
    <SectionContainer container num={1}>
      <div className="heading">
        <h1>404</h1>
        <h2>The page you&#39;re trying to reach does not exist</h2>
      </div>
    </SectionContainer>
    <SectionContainer num={2}>
      <Link to="/" className="go-back">
        go back home
      </Link>
    </SectionContainer>
  </div>
);

export default NotFoundPage;
